import Tippy from "@tippyjs/react";
import React, { useEffect, useState } from "react";
import { useAuth } from '../hooks/useAuth'
import { useDocumentData } from 'react-firebase-hooks/firestore'
import { fsDoc } from '../lib/firebase'

const Likes = ({
  like,
  slug,
  classes = "dark:bg-jacarta-700 absolute top-3 right-3 flex items-center space-x-1 rounded-md bg-white p-2",
}) => {
  const [likeState, setLikeState] = useState(
    typeof like === "string" ? +like - 1 : like - 1
  );
  const [likeNumber, setlikeNumber] = useState(likeState);
  const {user} = useAuth();
  const [data, loading, error] = useDocumentData(user && fsDoc(`burger/${slug}/votes/${user.id}`));
  const userLiked = !data ? false : data && data?.voted;
  const handleLike = () => {
    if (likeState >= likeNumber) {
      setlikeNumber((prev) => prev + 1);
    } else {
      setlikeNumber((prev) => prev - 1);
    }
  };

  return (
    <div className={classes}>
      <Tippy content={<span>Votos</span>}>
        {userLiked ? (
          <svg className="icon icon-heart-fill dark:fill-jacarta-200 fill-jacarta-500 fill-red h-4 w-4">
            <use xlinkHref="/icons.svg#icon-heart-fill"></use>
          </svg>
        ) : (
          <svg className="icon icon-heart-fill dark:fill-jacarta-200 fill-jacarta-500 h-4 w-4">
            <use xlinkHref="/icons.svg#icon-heart"></use>
          </svg>
        )}
      </Tippy>
      <span className="dark:text-jacarta-200 text-sm">{like}</span>
    </div>
  );
};

export default Likes;
